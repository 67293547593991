import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUser {
  cpUser: any;
  ssoUser: any;
  ssoAccessToken: null | string;
  menus: any;
}

export const initialState: any = {
  cpUser: {},
  ssoUser: {},
  ssoAccessToken: null,
  menus: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSSOAccessToken: (state, action: PayloadAction<string>) => {
      state.ssoAccessToken = action.payload;
    },
    ssoLogin: (state, action: PayloadAction<any>) => {
      state.ssoUser = { ...action.payload };
    },
    userLogin: (state, action: PayloadAction<any>) => {
      state.cpUser = { ...action.payload };
    },
    setMenus : (state,action :PayloadAction<any>) => {
      state.menu = {...action.payload};
    }
  },
});
const { actions, reducer } = userSlice;

export const { setSSOAccessToken, ssoLogin, userLogin } = actions;
export default reducer;
