
import axios from "axios";
import { handleAPIError } from "common/ErrorHandler";
import { useEffect, useState ,useCallback} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


const useFetch = (apiCall: Function, qparams: any,loader:any,initalValue:any={},) => {
  const [dataList, setDataList] = useState(initalValue);
  const [ setPageSize] = useState(qparams.pageSize);
  const [pagination, setPagination] = useState(qparams.pageNumber);  
  const [setSearchString] = useState(qparams.search)
  const dispatch = useDispatch();
  const history = useHistory();
  const contentSource = axios.CancelToken.source();
  
 
  useEffect(() => {
    fetchDetails();
    return ()=>{
      contentSource.cancel();
    }
  }, [qparams.pageNumber, qparams.pageSize, qparams.search, qparams.filter, qparams.pageOrder, qparams.sequenceOrder, qparams.sortOrder, qparams.fromDate, qparams.toDate, qparams.isActive, qparams.isDataUpdated, qparams.refresh]);

  const fetchDetails = useCallback(async () => {
   
    try {

      dispatch(loader(true));
      const response = await apiCall(
        qparams,
        contentSource
      );
      dispatch(loader(false));
      const responseData = await response.data;
       await responseData.data;
      if (responseData.status === 200) {
        setDataList(responseData);
      }
    } catch (error) {
      dispatch(loader(false));
      handleAPIError(error, history, dispatch);
     
    }
  },[qparams]);
 
 const  setDataLists = (value:any)=>{
    setDataList(value)
  }
  const upadatePageSize = (value:number)=>{
    setPageSize(value);
  }
  const upadateSearchString = (value:string)=>{
    setSearchString(value);
  }
  const updatePagination = (value:number) =>{
    setPagination(value)
  }
  return {dataList,pagination,setDataLists,upadatePageSize,upadateSearchString,updatePagination};
};

export default useFetch;
