import config from "../../config";

export const logger = (...messages: any[]) => {
  if (config.env === "local" || config.env === "dev") {
    console.log(...messages);
  }
};

export const logError = (...messages: any[]) => {
  if (config.env === "local" || config.env === "dev") {
    console.error(...messages);
  }
};
