import React from 'react'
import styles from 'styled-components'

const PageNotFoundDiv = styles.div`
    display: flex;
    justify-content: center;
    align-content:center;
    height: 100vh;
    

.center:{
    
    width:50%;
}
`
const PageNotFound= () => {
    return (
        <PageNotFoundDiv>
            <div style={{width:"60%",padding:"10px",textAlign:"center",margin:"auto",border:"2px solid black"}}>
            <h1>The page you are looking for is either not authorized or not found</h1>
            </div>
        </PageNotFoundDiv>
    )
}

export default PageNotFound
