import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const changeDateFormat = (date: any)=>{
  const [dateF] = new Date(date).toISOString().split('T')
  return dateF
}

export interface UserManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  pageOrder:string;
  loader:boolean;
  fromDate: string;
  toDate: string;
  isActive: any;
}

export const initialState: UserManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    pageOrder:"id desc",
    loader:false,
    fromDate: changeDateFormat(new Date().setDate(new Date().getDate() - 30)),
    toDate: changeDateFormat(new Date()),
    isActive: "",
   
};

const assetManagementSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
    searchAsset : (state,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
  setPageOrder:(state,action)=>{
        state.pageOrder = action.payload;
  },
  clearUserState : ()=>{
     return initialState
   },
   setLoader: (state, action: PayloadAction<boolean>) => {
    state.loader = action.payload;
  },
  setFromDate: (state, action) => {
    state.fromDate = action.payload;
  },
  setToDate: (state, action) => {
    state.toDate = action.payload;
  },
  setIsActive: (state, action)=> {
    state.isActive = action.payload;
  }
  },  
});
const { actions, reducer } = assetManagementSlice;

export const { setPageNumber ,setLoader,searchAsset,setPageSize,clearUserState,setPageOrder, setFromDate, setToDate, setIsActive} = actions;
export default reducer;
