import React from "react";
import FooterWrapper from "./styled";

const Footer = () => {
  return (
    <FooterWrapper className="aui-footer py-4 py-md-7 py-lg-5">
      <div className="container">
        <p className="text-center mb-7">
          *All health/medical information on this website has been reviewed and
          approved by the American Heart Association, based on scientific
          research and American Heart Association guidelines.&nbsp;
          <a
            href="https://www.heart.org/en/about-us/statements-and-policies/content-editorial-process"
            className="font-red"
            aria-label="Information on our content editorial process"
          >
            Use this link
          </a>
          &nbsp;for more information on our content editorial process.
        </p>
        <div className="row">
          <div className="col-md-3">
            <div
              className="aui-footer-logo mx-auto mx-md-0"
              role="img"
              aria-label="American Heart Association"
            />
            <div className="aui-footer-accordion">
              <div
                className="d-md-none d-flex justify-content-between align-items-center aui-footer-acc-h"
                id="contactaddress"
                data-toggle="collapse"
                data-target="#contactinfo"
                aria-expanded="false"
                aria-controls="contactinfo"
                role="button"
              >
                <span className="h8 mb-0 font-600">Contact Us</span>
                <i className="aha-icon-arrow-down mr-2" />
              </div>
              <div
                className="collapse"
                id="contactinfo"
                aria-labelledby="contactaddress"
              >
                <address className="aui-footer-address d-flex d-md-block flex-wrap mb-0">
                  <p>
                    <span className="h8 mb-0 font-600 d-block">
                      National Center
                    </span>
                    <span className="d-block">7272 Greenville Ave.</span>
                    <span className="d-block">Dallas, TX 75231</span>
                  </p>
                  <p className="pl-0 pl-sm-7 pl-md-0">
                    <span className="h8 mb-0 font-600 d-block">
                      Customer Service
                    </span>
                    <span className="d-block">1-800-AHA-USA-1</span>
                    <span className="d-block">1-800-242-8721</span>
                  </p>
                  <p>
                    <span className="h8 mb-0 d-block font-600">Hours</span>
                    <span className="d-block">
                      Monday - Friday: 7AM - 9PM CST
                    </span>
                    <span className="d-block">Saturday: 9AM - 5PM CST</span>
                    <span className="d-block">Closed on Sundays</span>
                  </p>
                </address>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-4">
                <div className="aui-footer-accordion">
                  <h6 className="h8 mb-0 font-600 d-none d-md-block">
                    About Us
                  </h6>
                  <div
                    className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                    id="ftrCol1"
                    data-toggle="collapse"
                    data-target="#ftrCol1Content"
                    aria-controls="ftrCol1Content"
                    aria-expanded="false"
                    role="button"
                  >
                    <h6 className="h8 mb-0 font-600">About Us</h6>
                    <i className="aha-icon-arrow-down mr-2 d-md-none" />
                  </div>
                  <div
                    className="collapse aui-footer-acc-b"
                    id="ftrCol1Content"
                  >
                    <ul className="mb-0">
                      <li>
                        <a
                          href="https://www.heart.org/en/about-us"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="About Americal Heart Association"
                        >
                          <span>About the AHA/ASA</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/about-us/annual-report"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Annual Report</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/about-us/aha-financial-information"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="American Heart Association Financial Information"
                        >
                          <span>AHA Financial Information</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://heart.jobs/?utm_campaign=heart.org-Footer&vs=2896&utm_medium=Other&utm_source=heart.org-Footer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Careers</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/about-us/international-programs"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>International Programs</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/news"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Latest Heart and Stroke News</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://newsroom.heart.org/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Americal Heart Association Media Newsroom"
                        >
                          <span>AHA/ASA Media Newsroom</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-4">
                <div className="aui-footer-accordion">
                  <h6 className="h8 mb-0 font-600 d-none d-md-block">
                    Get Involved
                  </h6>
                  <div
                    className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                    id="ftrCol2"
                    data-toggle="collapse"
                    data-target="#ftrCol2Content"
                    aria-expanded="false"
                    aria-controls="ftrCol2Content"
                    role="button"
                  >
                    <h6 className="h8 mb-0 font-600">Get Involved</h6>
                    <i className="aha-icon-arrow-down mr-2 d-md-none" />
                  </div>
                  <div
                    className="collapse aui-footer-acc-b"
                    id="ftrCol2Content"
                  >
                    <ul className="mb-0">
                      <li>
                        <a
                          href="https://www2.heart.org/site/SPageNavigator/donatenow_heart.html?s_src=20U2W1UEMG&s_subsrc=footer_donatenow"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Donate Now"
                        >
                          <span>Donate Now</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www2.heart.org/site/SPageNavigator/donatenow_legacy.html?s_src=20U2W1EEMM&sub_src=footer_memorial_gift"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Make a Memorial Gift"
                        >
                          <span>Make a Memorial Gift</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/get-involved/ways-to-give"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Ways to Give</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/get-involved/advocate"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Advocate</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/volunteer/opportunities"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Volunteer</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.goredforwomen.org/en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Go Red For Women</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.shopheart.org/?a=aha-heart.org-bottom-navigation&utm_source=heart.org&utm_medium=referral&utm_campaign=aha-heart.org-bottom-navigation"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>SHOP</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-md-5 mt-lg-0 px-xl-4">
                <div className="aui-footer-accordion">
                  <h6 className="h8 mb-0 font-600 d-none d-md-block">
                    Customer Support
                  </h6>
                  <div
                    className="d-flex d-md-none justify-content-between align-items-center aui-footer-acc-h"
                    id="ftrCol3"
                    data-toggle="collapse"
                    data-target="#ftrCol3Content"
                    aria-controls="ftrCol3Content"
                    aria-expanded="false"
                    role="button"
                  >
                    <h6 className="h8 mb-0 font-600">Customer Support</h6>
                    <i className="aha-icon-arrow-down mr-2 d-md-none" />
                  </div>
                  <div
                    className="collapse aui-footer-acc-b"
                    id="ftrCol3Content"
                  >
                    <ul className="mb-0">
                      <li>
                        <a
                          href="https://www.stroke.org/en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>American Stroke Association</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://cpr.heart.org/en"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="CPR and Emergency Cardiovascular Care"
                        >
                          <span>CPR &amp; ECC</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://professional.heart.org/en/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Professional Heart Daily</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.heart.org/en/about-us/aha-asa-website-directory"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>More Sites</span>
                          <i className="aha-icon-arrow-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aui-footer-inline">
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center py-md-4">
            <ul className="aui-footer-media p-0 m-0">
              <li>
                <a
                  href="https://twitter.com/American_Heart"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to twitter"
                >
                  <i className="aha-icon-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/AmericanHeart"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to facebook"
                >
                  <i className="aha-icon-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/american_heart/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to instagram"
                >
                  <i className="aha-icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/americanheartassoc"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to youtube"
                >
                  <i className="aha-icon-youtube" />
                </a>
              </li>
              <li>
                <a
                  href="https://in.pinterest.com/americanheart/_created/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to pinterest"
                >
                  <i className="aha-icon-pin" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/american-heart-association/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="connect to linkedin"
                >
                  <i className="aha-icon-linkedin" />
                </a>
              </li>
            </ul>
            <ul className="aui-footer-ref p-0 mb-0 mt-5 mt-md-0">
              <li>
                <a
                  href="https://nationalhealthcouncil.org/blog/introduction-to-the-standards-of-excellence-certification-program/"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block aui-extlink aui-council"
                  aria-label="National Health Council"
                >
                  National Health Council
                </a>
              </li>
              <li>
                <a
                  href="https://www.give.org/charity-reviews/national/health/american-heart-association-in-dallas-tx-173"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block aui-extlink aui-bbb"
                  aria-label="Better Business Bureau"
                >
                  Better Business Bureau
                </a>
              </li>
              <li>
                <a
                  href="https://www.charitynavigator.org/index.cfm?bay=search.summary&orgid=3260"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block aui-extlink aui-navigator"
                  aria-label="Charity Navigator"
                >
                  Charity Navigator
                </a>
              </li>
              <li>
                <a
                  href="https://ssl.comodo.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block aui-extlink aui-secureseal"
                  aria-label="Comodo Secure"
                >
                  Comodo Secure
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container aui-footer-link">
        <div className="row">
          <div className="col-12">
            <ul className="aui-footer-linkitem list-inline text-center mb-4">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://heart.jobs/?utm_campaign=heart.org-Footer&vs=2896&utm_medium=Other&utm_source=heart.org-Footer"
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/privacy-statement"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/medical-advice"
                >
                  Medical Advice Disclaimer
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/copyright"
                >
                  Copyright Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/accessibility-statement"
                >
                  Accessibility Statement
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/ethics-policy"
                >
                  Ethics Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/conflict-of-interest-policy"
                >
                  Conflict of Interest Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/linking-policy"
                >
                  Linking Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/editorial-guidelines"
                >
                  Content Editorial Guidelines
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/diversity-inclusion"
                >
                  Diversity
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/procurement-services/procurement-services-department"
                >
                  Suppliers &amp; Providers
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.heart.org/en/about-us/statements-and-policies/state-fundraising-notices"
                >
                  State Fundraising Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="text-center mb-0">
              &copy;2020 American Heart Association, Inc. All rights reserved.
              Unauthorized use prohibited.
            </p>
            <p className="text-center mb-0">
              The American Heart Association is a qualified 501(c)(3) tax-exempt
              organization.
            </p>
            <p className="text-center mb-2">
              *Red Dress<sup>TM</sup> DHHS, Go Red<sup>TM</sup> AHA ; National
              Wear Red Day&reg; is a registered trademark.
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center mx-auto pb-4 mt-3 aui-footer-info">
          <div
            className="aui-honconduct-img d-table"
            role="img"
            aria-label="Health on the Net certified 2019"
          />
          <p className="aui-p-footer pl-3 m-0">
            This site complies with the &nbsp;
            <a
              href="https://www.hon.ch/cgi-bin/HONcode/principles.pl?English"
              target="_blank"
              rel="noreferrer"
              className="font-red"
            >
              HONcode Standard
            </a>
            &nbsp;for trustworthy health information:&nbsp;
            <a
              href="https://www.healthonnet.org/HONcode/Conduct.html?HONConduct517595"
              target="_blank"
              rel="noreferrer"
              className="font-red"
            >
              verify here
            </a>
          </p>
        </div>
      </div>
    </FooterWrapper>
  );
};
export default Footer;
