import React, {useState, useEffect} from 'react'
import { decryptData } from 'common/utils/cryptoJs'
import { useSelector } from 'react-redux'
const ProfileContent = () => {
  const [users, setUsers] = useState<any>(null)
  useEffect(() => {
    const users = localStorage.getItem("carePlanUserInfo")
    if (users !== null) {
      const salt = process.env.REACT_APP_CLIENT_SALT
      const decryptedUser = decryptData(users, salt)
      setUsers(decryptedUser)
    }
  }, [])

  return (
    <div className="viewcontenttwo">
      <div className="cp-viewcont-ul w-100 mb-4 float-left">
        <div className="font-600 float-left" data-testid="firstName">FirstName</div>  
        <div className="float-left ml-5 pl-3">{users ? users.firstName: 'null'}</div>
      </div>  
      <div className="cp-viewcont-ul w-100 mb-4 float-left">
        <div className="font-600 float-left">LastName</div>
        <div className="float-left ml-5 pl-3">{users ? users.lastName : 'null'}</div>
      </div> 
      <div className="cp-viewcont-ul w-100 mb-4 float-left">
        <div className="font-600 float-left">Email</div>
        <div className="float-left ml-5 pl-3">{users ? users.email : 'null'}</div>
      </div> 
    </div>
  )
}

export default ProfileContent