import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ContentSyncState {
  pageNumber : number;
  search:string;
  pageSize:number;
  pageOrder:string;
  loader:boolean;
}

export const initialState: ContentSyncState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    pageOrder:"syncId desc",
    loader:false, 
};

const contentSyncSlice = createSlice({
  name: "vendorSync",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
    searchVendorSync : (state,action:PayloadAction<string>)=>{
      state.pageNumber =1;
      state.search =  action.payload
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
  setPageOrder:(state,action)=>{
        state.pageOrder = action.payload;
  },
  clearVendorSync: ()=>{
     return initialState
   },
   setLoader: (state, action: PayloadAction<boolean>) => {
    state.loader = action.payload;
  }
  },  
});
const { actions, reducer } = contentSyncSlice;

export const { setPageNumber ,setLoader,searchVendorSync,setPageSize,clearVendorSync,setPageOrder} = actions;
export default reducer;
