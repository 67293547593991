import styled from "styled-components";
export const DialogBoxWrapper = styled.div`
.cpr-confirm-alert{font-size:18px;max-width:30rem;
  p{margin-bottom:21px;}
  span{display:block;} 
.cpr-buttons{
  button{width:115px;}
  button:nth-child(1){margin-right:20px;}
}}
`;

