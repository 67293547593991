import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface FilterInterface {
  category:string[];
  careplan:string[];
  level:string[];
  contentType:string[];
  coach:string[]
}
export interface CategoryInterface{
  id: number;
  name: string;
  code:string;
  type?: string;
  level?: number;
  parentid?: number;
  createdAt: string;
  updatedAt:string;
  isChecked?:boolean;
}

export interface DropdownInterface {
  category:any,
  careplan:any,
  level:any,
  contentType:any,
  coach:any,
  owner:any,
  domain:any,
}
export interface CategoryPayload{
  name:string;
  value : CategoryInterface[]
}
export interface ContentManagementState {
  pageNumber: number;
  search: string;
  pageOrder:string;
  sequenceOrder:"";
  sortOrder:"",
  pageSize: number;
  loader:boolean;
  filter: FilterInterface;
  data : DropdownInterface;
}

export const initialState: ContentManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  pageOrder:"code asc",
  sequenceOrder:"",
  sortOrder:"",
  loader:false,
  filter:{
   category:[],
   careplan:[],
   level:[],
   contentType:[],
   coach:[],
  },
  data:{
    category:[],
    careplan:[],
    level:[],
    contentType:[],
    coach:[],
    owner:[],
    domain:[],
  }
};
interface CategoryFilter{
  name:string;
  value : string[];
}

interface CategoryData{
  name:string;
  value : any;
}

const contentManagementSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    searchContent: (state, action: PayloadAction<string>) => {
      state.pageNumber=1;
      state.search = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearState: (state) => {
       return initialState;
    },
    clearFilterData:(state)=>{
       state.filter = JSON.parse(JSON.stringify(initialState.filter));
    },
    setPageOrder:(state,action)=>{
      state.pageOrder = action.payload;
},setSequenceOrder:(state,action)=>{
  state.sequenceOrder = action.payload;
},setSortOrder:(state,action)=>{
  state.sortOrder = action.payload;
},
    setCategoryFilter : (state,action:PayloadAction<CategoryFilter>)=>{
      state.pageNumber = 1;
      switch(action.payload.name){
        case "category":state.filter.category = [...action.payload.value];break;
        case "contentType":state.filter.contentType = [...action.payload.value];break;
        case "careplan":state.filter.careplan = [...action.payload.value];break;
        case "level":state.filter.level = action.payload.value;break;
        case "coach":state.filter.coach = [...action.payload.value];break;

      }
      
    },
    setCategoryData : (state,action:PayloadAction<CategoryData>)=>{
    
      switch(action.payload.name){
        case "category":state.data.category = action.payload.value;break;
        case "contentType":state.data.contentType = action.payload.value;break;
        case "careplan":state.data.careplan=action.payload.value;break;
        case "level":state.data.level=action.payload.value;break;
        case "coach":state.data.coach=action.payload.value;break;
        case "domain":state.data.domain=action.payload.value;break;
        case "owner":state.data.owner=action.payload.value;break;
      }
      
    }


  }
});
const { actions, reducer } = contentManagementSlice;

export const { setPageNumber,setPageOrder,setSequenceOrder,setSortOrder,setLoader,clearFilterData, searchContent,setPageSize,clearState,setCategoryFilter,setCategoryData } = actions;
export default reducer;
