import { showToast } from "components/Toast/toast.slice";
import store from "app/store";
import { setAuthStatus } from "app/authReducer";

export const SessionExpired = async(history:any,dispatch:any)=>{
    dispatch(setAuthStatus({isAuthenticated:false}));
setTimeout(()=>{
  store.dispatch(showToast({title:"Session Timeout!",message:"Please login again"}));

},500)
    
    store.dispatch({type:"LOGGED_OUT"});
    localStorage.removeItem("carePlanAccessToken");
    localStorage.removeItem("carePlanRefreshToken");
    localStorage.removeItem("carePlanPermission")
    localStorage.removeItem("carePlanUserInfo")
    history.replace("/");
    sessionStorage.clear();
}

export const HandleCatch = async(error:any,history:any) =>{
    if(error.code ==="NOT_FOUND"){
      const pathname = window.location.pathname;
      const paths = pathname.split("/");
      if(paths.indexOf("view")!==-1){
        paths.splice(paths.indexOf("view"));
      }else if(paths.indexOf("edit")!==-1){
        paths.splice(paths.indexOf("edit"));
      }
      history.replace(paths.join("/"));
    }
    store.dispatch(showToast(error));
}
export const handleAPIError = (error:any,history:any,dispatch:any)=>{
    if(error.response){
      try{
        const errorResponse = error.response.data.error;
        if (errorResponse.code === "ACCESS_DENIED" && errorResponse.message === 'Invalid RolePermission'){
          HandleCatch({
            title: "Access Denied!",
            message: "Inavlid Role Permission",
          },history)
        }else if (errorResponse.code === "ACCESS_DENIED" ) {
          SessionExpired(history, dispatch);
        } else {
          HandleCatch(errorResponse,history);
        }
      }catch(error){
        HandleCatch({
          title: "Server Error!",
          message: "Something went wrong, please try again later",
        },history);
      }
        
      }else{
        if (error.message === "Network Error") {
          HandleCatch({
            title: "Server Error!",
            message: "Something went wrong, please try again later",
          },history);
        }
      }       
} 