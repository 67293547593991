import styled from "styled-components";
export const AccountInformationWrapper = styled.div`
.cp-profile-container{padding:0px 1.25rem;
  .child-container{min-height:400px;}
  @media only screen and (min-width: 1361px){width: 80rem;}
  @media (min-width: 768px){padding:0px 2.5rem;}
  .myAccount-left{width:308px;background:#F8F8F8;
  .myAccount-heads{font-size: 18px;color: #ffffff;background: #C10E21;}}
  .myAccount-right{width:calc(100% - 324px);border:none;
    .cp-viewcont-ul{font-size: 16px;
    div:nth-child(1){width:120px;}}}
    @media only screen and (max-width: 991.5px){      
      .child-container{min-height:40px;flex-direction:column;}
      .myAccount-left,.myAccount-right{width:100%;margin-left:0px !important;}
      .myAccount-heads{padding: 12px 23px !important;margin-bottom:0px !important;}
      .myAccount-right{border:1px solid #BCC3CA;padding:24px 27px !important;float:left;display:none;transition: display 2s;}
      .myAccount-right{display:none;}
      i.aha-icon-arrow-down{display:block !important;}
      i.aha-icon-arrow-down::before{font-size: 10px;font-weight: normal;}
    } 
    @media only screen and (max-width: 767.5px){    
    .myAccount-right .cp-viewcont-ul div:nth-child(2){margin-left:0px !important;word-break: break-all;}
  }
  @media only screen and (max-width: 575.5px){
    .myAccount-right .cp-viewcont-ul div{width:100%;}
    .myAccount-right .cp-viewcont-ul div:nth-child(2){padding-left:0px !important;}
  }  
}
`;

export const AnotherWraper = styled.div``;