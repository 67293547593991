import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CarePlanState {
  pageNumber : number;
  search:string;
  loader:boolean;
  pageSize:number;
  pageOrder:string;
}

export const initialState: CarePlanState = {
    pageNumber: 1,
    search : "",
    loader:false,
    pageSize:25,
    pageOrder:""

};

const carePlanSlice = createSlice({
  name: "careplan",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
    setPageOrder:(state,action)=>{
      state.pageOrder = action.payload;
  },
    searchCarePlan : (state,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload
  }, clearCarePlanState : ()=>{
    return initialState
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
  setLoader:(state,action:PayloadAction<boolean>)=>{
      state.loader = action.payload;
  }
   
  },  
});
const { actions, reducer } = carePlanSlice;

export const { setPageNumber, setPageSize,setPageOrder,setLoader,searchCarePlan,clearCarePlanState} = actions;
export default reducer;
