import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  currentState: {},
};

const addCareplanSlice = createSlice({
  name: "addCareplan",
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState;
    },
    
    setCurrentState: (state, action) => {
      state.currentState = action.payload;
    },
  },
});
const { actions, reducer } = addCareplanSlice;

export const { clearState, setCurrentState } = actions;
export default reducer;
