const CONSTANTS = {
  PENDING: "PENDING",
  REGISTRED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",
  PAGINATION_LIMIT : 5,
  KEY_ENTER:13,
  KEY_SPACEBAR:32,
  KEY_ESCAPE:27,
  KEY_DOWN:40,
  KEY_UP:38,
  TableRecordSizeList :[10, 25, 50, 100]
};

export default CONSTANTS;
