/* eslint-disable no-debugger, no-console */
import React, { useRef, useState } from "react";
import { isEqual } from "lodash";
import { useFormik} from "formik";
import { useHistory } from 'react-router-dom';
import { Feedback } from "services/api/thirdparty.api";
import * as Yup from "yup";
import { ContactUsWrapper } from "./styled";
import store from "app/store";
import { useDispatch } from "react-redux";
import CONSTANTS from "common/constants";
import { FormikInput, FormikTextArea } from "components/FormikInput";
import DialogBox from "components/DialogBox";
import axios from "axios";
import "./styled.css";
import { handleAPIError } from "common/ErrorHandler";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { showToast } from "components/Toast/toast.slice";
import {
   useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
export interface DialogBoxProperty {
   message: string;
   confirmHandler: Function;
 }

const addFeedbackInitialValue = {
   name: "",
   email: "",
   feedback: "",
 };


const ContactUsChild = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const modalRef = useRef<HTMLDivElement>(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [dialogBoxProp, setDialogBoxProp] = useState<DialogBoxProperty | null>(
      null
    );
    const keydown_contactus_closebtn = (event: any) => {
      if (event.keyCode === CONSTANTS.KEY_ENTER || event.keyCode === CONSTANTS.KEY_SPACEBAR) {
        event.currentTarget.click();
      } 
    }
    const contactus_closebtn = () => {
       formik.handleReset(true)
      document.querySelector('.contact-us-wrap')?.classList.add('d-none');
      props.switch(false);
    }
    const addFeedback = async (request: any) => {
      if (executeRecaptcha) {
         await  executeRecaptcha("contactUs").then((response:any)=>{
            request["recaptchaToken"] = response
         })
      }
      const source = axios.CancelToken.source();
      try {
         dispatch(setPageLoadingStatus({ isPageLoading: true }));
         const response = await Feedback(request);
         store.dispatch(
            showToast({
               title: "Feedback",
               message: "Feedback saved successfully",
               type: "success",
            })
         );
         formik.handleReset(true)
         document.querySelector('.contact-us-wrap')?.classList.toggle('d-none');
         props.switch(false);
         dispatch(setPageLoadingStatus({ isPageLoading: false }));
      } catch (error) {
         source.cancel();
         formik.setSubmitting(false);
         handleAPIError(error, history, dispatch);
         dispatch(setPageLoadingStatus({ isPageLoading: false }));
      }
    };
      
     const formik = useFormik({
      initialValues:addFeedbackInitialValue,
      validationSchema: Yup.object().shape({
         name: Yup.string().required("Name is required").min(5,"Name must be at least 5 character").max(30),
         email: Yup.string()
            .required("Email is required")
            .email("Enter valid email"),
         feedback: Yup.string()
         .required("Feedback required"),
      }),
    
      validateOnBlur: true,
      validateOnMount: false,
      validateOnChange: true,
      onSubmit: (values, actions) => {
         const request: any = {
            name: values.name,
            email: values.email,
            feedback: values.feedback,
         };
        addFeedback(request);
      },
     });
    
      const onCancelHandler = () => {
        if (isEqual(formik.values, addFeedbackInitialValue)) {
           formik.handleReset(true)
           document.querySelector('.contact-us-wrap')?.classList.toggle('d-none');
           props.switch(false);
        } else {
           const dialogProp = {
              message: "Are you sure you want cancel the changes?",
              confirmHandler: () => {
                 modalRef.current?.click();
                 formik.handleReset(true)
                 document.querySelector('.contact-us-wrap')?.classList.toggle('d-none');
                 props.switch(false);
              },
           };
           setDialogBoxProp(dialogProp);
           modalRef.current?.click();
        }
     };

   return (
      <ContactUsWrapper>
      <div className="position-absolute contact-us-wrap" role="alert">
              <div className="contactus-closebtn position-absolute" role="button" aria-label="contact us close button link" onKeyDown={keydown_contactus_closebtn} onClick={contactus_closebtn} tabIndex={0}>X</div>
              <div className="col-lg-12 pt-4">
              <h1 className="contact-heads">Contact Us</h1>
              </div>
            <form
              onSubmit={formik.handleSubmit}
              >
            <div className="form-group required">
                <label htmlFor="name" className="col-sm-12 col-form-label">Name</label>
                <div className="col-sm-12 float-right">
                <FormikInput
                              id="name"
                              required
                              name="name"
                              formik={formik}
                              aria-label="enter Name"
                              aria-required="true"
                              aria-invalid={
                                 formik.errors.name && formik.touched.name ? true : false
                              }
                              value={formik.values.name}
                   />
                </div>
                {formik.errors.name && formik.touched.name ? (
                           <span className="invalidemail" id="invalidname" role="alert">
                              {formik.errors.name}
                           </span>
                        ) : null}
            </div>
            <div className="form-group required">
                <label htmlFor="inputemail" className="col-sm-12 col-form-label">Email</label>
                <div className="col-sm-12 float-right">
                <FormikInput
                    id="email"
                    required
                    name="email"
                    formik={formik}
                    aria-label="enter Email"
                    aria-required="true"
                    aria-invalid={
                      formik.errors.email && formik.touched.email ? true : false
                              }
                              value={formik.values.email}
                />
                </div>
                {formik.errors.email && formik.touched.email ? (
                    <span
                              className="invalidemail"
                              id="invalidemail"
                              role="alert"
                           >
                              {formik.errors.email}
                    </span>
                ) : null}
            </div>
            <div className="form-group required">
                <label htmlFor="feedback" className="col-sm-12 col-form-label">Feedback</label>
              <div className="col-sm-12 float-right">
                <FormikTextArea
                      id="feedback"
                      required
                      name="feedback"
                      formik={formik}
                      aria-label="enter feedback"
                      aria-required="true"
                      aria-invalid={
                        formik.errors.feedback && formik.touched.feedback
                               ? true
                               : false
                           }
                       value={formik.values.feedback}
                ></FormikTextArea>
              </div>
              {formik.errors.feedback && formik.touched.feedback ? (
                           <span
                              className="invalidemail"
                              id="invalidfeedback"
                              role="alert"
                           >
                              {formik.errors.feedback}
                           </span>
                        ) : null}
            </div>
            
            <div className="col-lg-12 pb-4 float-left">
            <div className="brdr-top pb-4 mt-3 w-100 float-left"></div>
            <button  
              className="btn btn-round btn-primary mr-3"
              type="button"
              aria-label="add feedback cancel"
              data-testid="cancel"
              onClick={onCancelHandler} onKeyDown={keydown_contactus_closebtn}
             >
               Cancel
            </button>

            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="btn btn-round btn-secondary ml-3"
              aria-label="add feedback save"
              data-testid="submit"
            >
              Save
            </button>
            </div>
            </form>
            </div>
         <DialogBox ref={modalRef} {...dialogBoxProp}></DialogBox>
      </ContactUsWrapper>
   );
};
export default ContactUsChild;
