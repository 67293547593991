import styled from "styled-components";

const HeaderWrapper = styled.header`
  .aui-skip-content {
    padding: 16px;
    color: #c10e21;
    position: absolute;
    left: -9999px;
    width: 100%;
    top: 0;
    text-align: center;
    background-color: #fff;
    &:focus {
      left: 0;
      z-index: 500;
    }
  }
`;
export default HeaderWrapper;
