/* eslint-disable no-debugger, no-console */
import React ,{useEffect}from "react";
import PropTypes from "prop-types";
import { Route, RouteProps, useHistory } from "react-router-dom";
import DefaultLayout from "pages/_layouts/default";
import AdminLayout from "pages/_layouts/admin";
import { UserManager } from "oidc-client";
import OidcSettings from "./OidcSettings";
import LoginLayout from "pages/_layouts/login";
import store from "app/store";
import { setSSOAccessToken, ssoLogin } from "pages/SSOLogin/user.slice";
import { logger } from "common/utils/logger.utils";
import { encryptData } from "common/utils/cryptoJs";
declare global {
  interface Window { userManager: any; }
}
logger("route page");
const userManager = new UserManager(OidcSettings);
window["userManager"] = userManager;
window["userManager"].events.addUserLoaded(onUserLoaded);
window["userManager"].events.addUserUnloaded(onUserUnloaded);

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  isPrivate?: boolean;
  hideHeader?: boolean;
  adminRoute?: boolean;
  hospitalRoute?: boolean;
  protectedRoute?: boolean;
  title?:string
}

export default function RouteWrapper({
  component: Component,
  isPrivate,
  adminRoute,
  protectedRoute,
  hospitalRoute,
  hideHeader,
  title,
  ...rest
}: PrivateRouteProps) {
  
  const history = useHistory();
 
  let Layout = DefaultLayout;
  if (hideHeader) {
    Layout = LoginLayout;
  } else{
    Layout = AdminLayout;
  }
/** 
 * change document title for routes.
*/
useEffect(() => {
  userManager.getUser().then((user) => {
    logger("GET USER");
    if (user !== null && user !== undefined) {
        onUserLoaded(user);
        //checkRoleAndRedirect();
    } else if (window.location.href.includes("#id_token")) {
        userManager.signinRedirectCallback().then(() => {
            window.history.replaceState({}, "", "/");
        }).catch(function (err) {
        });
    } else {
        if (isPrivate) {
            history.push('/')
        }
    }
  }).catch((error) => {
    logger('error inside catch block :: ', error);
  })
  if(title)
    document.title = title;
    return ()=>{
      
    }
}, [title])


  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return (
      
    <Route
      {...rest}
      render={(props) => (
        <Layout> 
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function onUserLoaded(user: any) {
  logger("UserLoaded...........")
  if (user.state?.tenantId) {
      user.profile.tenantId = user.state?.tenantId;
  }
  logger("SSO TOKEN DETAILS:", user);
  store.dispatch(ssoLogin(user.profile));
  getCarePlanUserInfo(user.profile)
  store.dispatch(setSSOAccessToken(String(user.access_token)));
}
function getCarePlanUserInfo (userInfo: any) {
  localStorage.setItem("carePlanUserInfo", encryptData(userInfo, process.env.REACT_APP_CLIENT_SALT));

}
function onUserUnloaded() {
  logger("User LOGOUT");
  store.dispatch(ssoLogin({ user: {} }));
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  hideHeader: false,
  adminRoute: false,
  hospitalRoute: false,
  protectedRoute: false,
  isSignedIn: false,
};
