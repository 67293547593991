import { combineReducers } from "@reduxjs/toolkit";
import loaderReducer from "components/Loader/loader.slice";
import toastReducer from "components/Toast/toast.slice";
import authReducer from "app/authReducer"
import contentReducer from "pages/Contentmanagement/contentManagementSlice";
import editContentReducer from "pages/Editcontent/editContentSlice"
import addContentReducer from "pages/Addcontent/addContentSlice";
import addContentReducer1 from "pages/Addcontent2/addContentSlice";
import assetReducer from "pages/Assetmanagement/assetManagementSlice"
import carePlanReducer from "pages/Careplan/carePlanSlice"
import vendorReducer from "pages/Vendormanagement/vendorManagementSlice";
import participantReducer from "pages/Participants/participantsSlice";
import contentSyncReducer from "pages/ContentSync/contentSyncSlice";
import vendorSyncReducer from "pages/VendorSync/vendorSyncSlice";
import addCareplanReducer from "pages/Linkcontent/addCareplanSlice";
import assessmentReducer from "pages/Assessmentmanagement/assessmentManagementSlice";
import userManagement from "pages/Usermanagement/userSlice";
import roleManagement from "pages/RoleManagement/roleSlice";
import assessmentSetting from "pages/Assessmentsettings/assessmentSettingSlice"
import userReduce from "pages/SSOLogin/user.slice";
import auditLogReducer from "pages/AuditLog/auditLogSlice";
import vendorDetailReducer from "pages/VendorDetailReport/vendorDetailReportSlice";
import userDetailReducer from "pages/UserDetailReport/userDetailReportSlice";
import participantDetailReducer from "pages/ParticipantDetailReport/participantDetailReportSlice";
import vendorActivityReducer from "pages/VendorActivityReport/vendorActivitySlice";
import feedbackActivityReducer from "pages/Feedback/feedbackSlice"
import batchUpdateReducer from "pages/BatchUpdate/batchUpdateSlice"
import batchUpdateViewSuccessReducer from "pages/BatchUpdateView/batchViewSuccessSlice"
import batchUpdateViewFailedReducer from "pages/BatchUpdateView/batchViewFailedSlice"
import brokenLinkSlice from "pages/BrokenLinks/brokenlinksSlice"
import brokenLinksSuccessSlice from "pages/BrokenLinksView/brokenSuccessSlice";
import brokenLinksFailedSlice from "pages/BrokenLinksView/brokenFailedSlice";


const appReducer = combineReducers({
  loader: loaderReducer,
  toast: toastReducer,
  auth : authReducer,
  editcontent :editContentReducer,
  content: contentReducer,
  addcontent:addContentReducer,
  addcontent1:addContentReducer1,
  auditLog: auditLogReducer,
  asset : assetReducer,
  carePlan: carePlanReducer,
  vendor:vendorReducer,
  vendorDetails: vendorDetailReducer,
  contentSync: contentSyncReducer,
  vendorSync: vendorSyncReducer,
  participant:participantReducer,
  addCareplan:addCareplanReducer,
  assessment:assessmentReducer,
  user:userReduce,
  userManagement:userManagement,
  roleManagement:roleManagement,
  assessmentSetting:assessmentSetting,
  userDetails: userDetailReducer,
  participantDetail: participantDetailReducer,
  vendorActivity: vendorActivityReducer,
  feedbackActivity: feedbackActivityReducer,
  batchUpdate: batchUpdateReducer,
  batchUpdateViewSuccess: batchUpdateViewSuccessReducer,
  batchUpdateViewFailed: batchUpdateViewFailedReducer,
  brokenLink: brokenLinkSlice,
  brokenLinkViewSuccess: brokenLinksSuccessSlice,
  brokenLinkViewFailed: brokenLinksFailedSlice
});

export type RootState = ReturnType<typeof appReducer>;

 const rootReducer = (state:any,action:any)=>{
      if(action.type ==="LOGGED_OUT"){
        state = undefined;
      }
      return appReducer(state,action);
    }
export default rootReducer;


