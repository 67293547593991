import { createSlice } from "@reduxjs/toolkit";

interface AssessmentSettingInterface{
    category:string[];
    group:string[];
    units:string[];
    optionStyle:string[];
}
const initialState :AssessmentSettingInterface= {
    category:[],
    group:[],
    units:[],
    optionStyle:[]
}
const assessmentSlice = createSlice({
    name:"assessmentSetting",
    initialState,
    reducers:{
        addCategory : (state,action)=>{
            state.category = [...state.category,action.payload]
        },
        addGroup : (state,action)=>{
            state.group = [...state.group,action.payload]
        },
        addUnits:(state,action)=>{
            state.units = [...state.units,action.payload];
        },
        addOptionStyle:(state,action)=>{
            state.optionStyle =[action.payload];
        }
    }
})
const {actions,reducer} = assessmentSlice;
export const {addCategory,addGroup,addUnits,addOptionStyle} = actions;
export default reducer;
