import styled from "styled-components";
const AdminHeaderWrapper = styled.header`
.dropdown a.dropdown-item{border-top: 1px solid #e3e3e3;}
.dropdown a.dropdown-item:nth-of-type(1){border:none;}
.aha-heart-logo.aha-contentone{background: url(/../../images/AHA-100.svg) no-repeat;height: 100px;background-size: 82%;width: 106px;}
.cp-bdr-resp{border-bottom:1px solid #e3e3e3;}
.dropdown-item.dropdown-noclr{color: #222328 !important;}
.l-padding-0{padding:0px 2.5rem 0px 2.5rem;
@media only screen and (min-width:1363px){ 
    padding:0px;
   }
}
.cp-spl-lione{display:none;}
.navbar-nav.cp-altone li:last-child{margin-right:0px !important;}
.dropdown-submenu .dropdown-menu{top:53px;
> a{font-size:14px;}}
.dropdown-submenu a:after{content: '';position: absolute;display: block;width: 0;
z-index: 1;border-style: solid;border-color: #C10E21 transparent;border-width: 0 6.5px 8px;bottom:0px;left: 50%;margin-left: -6.5px;opacity:0;-webkit-transition: all 0.4s !important;transition: all 0.4s !important;}
.dropdown-submenu > a:hover:after{opacity:1;-webkit-transition:all 0.7s cubic-bezier(0.76, 0.08, 0.18, 0.99);transition:all 0.7s cubic-bezier(0.76, 0.08, 0.18, 0.99);}
.dropdown-submenu:hover .dropdown-menu{display: block;}
a.li-active:before{visibility:visible !important;-webkit-transform: translateY(0px) !important;transform: translateY(0px) !important;opacity: 1 !important;}
@media only screen and (min-width:768px){
  .aui-main-header .aui-pri-header-t{padding: 1.875rem 2.5rem 1.25rem;}
  .aui-main-header .aui-header-t{padding: 1.25rem 2.5rem 1.25rem;}
}
@media only screen and (min-width:992px){
  .cp-altone{
    .nav-link{font-size:16px;padding:15px 0px;}}
}
@media only screen and (min-width:1361px) {  
  .aui-header-content.aui-header-t{padding:10px 0px;}
}
@media only screen and (max-width: 991.5px){
  .dropdown-menu > div.dropdown-item:nth-of-type(1){border-bottom: 1px solid #e3e3e3;}
  .aui-main-header .aui-header-b > * {width: 100%;}
  .cp-spl-lione{display:block;}
  .cp-bdr-resp{border-bottom-style: none;}
  .aui-main-header .dropdown-toggle:after {right: 5px;top: 45%;position: absolute;}
}

`;
export default AdminHeaderWrapper;
