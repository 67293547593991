import styled from "styled-components";

export const DashboardWrapper = styled.div`
body{overflow-x:hidden;}
.cp-h2{font-size:26px;margin:24px 0px 10px 15px;}
.l-padding-0{padding-top:0px;padding-bottom:0px;}
.cp-bdr-resp{border-bottom:1px solid #e3e3e3;}
.dropdown-item.dropdown-noclr{color: #222328 !important;}
.l-padding-0{padding-top:0px;padding-bottom:0px;}
.cp-spl-lione{display:none;}
a.li-active:before{visibility:visible !important;-webkit-transform: translateY(0px) !important;transform: translateY(0px) !important;opacity: 1 !important;}
@media only screen and (max-width: 991.5px){
  .aui-main-header .aui-header-b > * {width: 100%;}
  .cp-spl-lione{display:block;}
  .cp-bdr-resp{border-bottom-style: none;}
  .aui-main-header .dropdown-toggle:after {right: 5px;top: 45%;position: absolute;}
}
@media only screen and (max-width:767.5px){
 h1.cp-h2{font-size:24px;margin:36px 0px 0px 15px;}
}
.dashboard-wrapper{padding:0px 0px 50px 0px;}
.products{
  height:110px;width:337px;margin:30px 0px;cursor:pointer;
@media only screen and (max-device-width:991px){width:100%;}
.icons{width: 109px;height: 110px;float: left;margin: -2px;}
ul,.icon-contents{float: left;list-style-type: none;float:left;color: #222328;margin:15px 0px 0px 24px;padding:0px;
@media only screen and (min-width: 992px) and (max-width:1100px){margin-left:10px;}
@media only screen and (max-width:360px){margin-left:10px;}
*{font-family:'Montserrat', sans-serif;}
}
.icon-contents  h2{font-weight: 500;font-size: 22px;line-height: 30px;}
,.content-exp{font-weight: 700;font-size: 29px;margin-top: 17px;line-height: 30px;}
}
.products-one{border: 2px solid #C10E21;
  .icons{background: #C10E21;}}
.products-two{border: 2px solid #F03F37;
  .icons{background:#F03F37;}}
.products-three{border: 2px solid #FF9131;
  .icons{background: #FF9131;}}
.products-four{border: 2px solid #39A24D;
  .icons{background:#39A24D;}}
.products-five{border: 2px solid #525163;
  .icons{background: #525163;}}
.products-six{border: 2px solid #001E71;
  .icons{background:#001E71;}}
`;
export const AnotherWraper = styled.div``;
