import { axiosAccounts, axiosLogoutApi } from './axios'

export interface Credentials {
  email: string;
  password: string;
  validate?: boolean;
  touch?: boolean;
  idToken: string;
}

export interface Token {
  idToken: string;
}

export const adminSignIn = async (user: Credentials): Promise<any> => {
  return axiosAccounts.post('/adminLogin?permissionData=true', user);
}

export const adminLogout = async (token: Token): Promise<any> => {
  return axiosLogoutApi.post('/logout?expireAll=true', token)
}

export const ssoSignUp = async (token: Token): Promise<any> => {
  return axiosAccounts.post('/ssoSignUp', token);
}

export const getClientSecret = async (code: any): Promise<any> => {
  return axiosAccounts.get(`/clientCredentials?code=${code.join()}`);
}

export const getUserByFilter = async (content: any): Promise<any> => {
  const vendorId = content.filter.vendorsId;
  return axiosAccounts.get(
    `/users?pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&pageNumber=${content.pageNumber}&vendorId=${vendorId.join(",")}`
  );
};

export const contactUs = async (vendor: any): Promise<any> => {
  return axiosAccounts.get(
    `/adminContactUs?pageOrder=${vendor.pageOrder}&pageSize=${vendor.pageSize}&q=${vendor.search}&pageNumber=${vendor.pageNumber}`
  );
};

export const viewFeedback= async (id:string): Promise<any> => {
  return axiosAccounts.get(`/userComments/${id}`);
};

export const getUserDetailsByFilter = async (user: any): Promise<any> => {
  if (user.isActive){
    return axiosAccounts.get(
      `/users?pageSize=${user.pageSize}&q=${user.search}&pageNumber=${user.pageNumber}&pageOrder=${user.pageOrder}&fromDate=${user.fromDate}&toDate=${user.toDate}&isActive=${user.isActive}`
    );
  } else {
    return axiosAccounts.get(
      `/users?pageSize=${user.pageSize}&q=${user.search}&pageNumber=${user.pageNumber}&pageOrder=${user.pageOrder}&fromDate=${user.fromDate}&toDate=${user.toDate}`
    );
  }
};

export const downloadUserData = async (user: any, pageNumber: any): Promise<any> => {
  return axiosAccounts.get(
    `/users?pageSize=${process.env.REACT_APP_FILE_DOWNLOAD_PAGESIZE}&q=${user.search}&pageNumber=${pageNumber}&pageOrder=${user.pageOrder}&fromDate=${user.fromDate}&toDate=${user.toDate}&isActive=${user.isActive}`
  );
};

export const getUserById = async (id: any): Promise<any> => {
  return axiosAccounts.get(
    `/users/${id}`
  );
};

export const getRoles = async (): Promise<any> => {
  return axiosAccounts.get(
    `/roles?level=true`
  );
};

export const getRolesByFilter = async (content: any): Promise<any> => {
  return axiosAccounts.get(
    `/roles?level=true&pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&pageNumber=${content.pageNumber}}`
  );
};

export const updateUser = async (user: any,notify:boolean): Promise<any> => {
  return axiosAccounts.patch(`/users/${user.id}?notify=${notify}`, user);
}

export const updateRole = async (role: any): Promise<any> => {
  return axiosAccounts.patch(`/roles/${role.id}`, role);
}
export const userComments = async (formData: any): Promise<any> => {
  return axiosAccounts.post(`/userComments`, formData);
};

export const getHelpIcons= async (search: string): Promise<any> => {
  return axiosAccounts.get(`/helpIcons?q=${search}`);
};