import React, { useEffect, useState } from "react";
import AdminHeaderWrapper from "./styled";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenusEntity, setAuthStatus } from "app/authReducer";
import store from "app/store";
import { logger } from "common/utils/logger.utils";
import { decryptData } from "common/utils/cryptoJs";
import { useHistory } from "react-router-dom";
import useFetch from "customHooks/useFetch";
import { adminLogout } from "services/api/adminLogin.api";
import { handleAPIError } from "common/ErrorHandler";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import Feedback from "pages/Feedback/feedback";
import { isEmpty } from "lodash"
export const AdminHeader = (props: any) => {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState<MenusEntity>();
  const [user, setUsers] = useState<any>(null)
  const [feedBackFlag, setFeedBackFlag] = useState(false);
  const [reportFlag, setreportFlag] = useState(true);
  const authState = useSelector((state: any) => {
    return state.auth;
  });
  const { ssoUser } = useSelector((state: any) => {
    return state.user
  })

  useEffect(() => {
    const users = localStorage.getItem("carePlanUserInfo")
    if (users !== null) {
      const salt = process.env.REACT_APP_CLIENT_SALT
      const decryptedUser = decryptData(users, salt)
      setUsers(decryptedUser)
    }
  }, [ssoUser])


  useEffect(() => {
    const persmission = localStorage.getItem("carePlanPermission")
    const salt = process.env.REACT_APP_CLIENT_SALT;
    if (persmission !== null) {
      const decryptedDATA = decryptData(persmission, salt);
      setPermission(decryptedDATA)
      decryptedDATA.menus.map((data: any) => {
        if (data.code == "feedback") {
          setFeedBackFlag(true)
        }
        if (data.code == "reports") {
          data.menus.length > 4 ? setreportFlag(true) : setreportFlag(false)
        } 
      })
    }
  }, [authState.permission])
  const signOut = async (e: any) => {
    let carePlanToken = localStorage.getItem("carePlanAccessToken")
    let token: any = {
      accessToken: carePlanToken
    }
    e.preventDefault();
    try {
      const { status } = await adminLogout(token)
      if (status === 200) {
        window.userManager.signoutRedirect();
        dispatch(setAuthStatus({ isAuthenticated: false }));
        store.dispatch({ type: "LOGGED_OUT" });
        localStorage.removeItem("actions");
        localStorage.removeItem("carePlanAccessToken");
        localStorage.removeItem("carePlanRefreshToken");
        localStorage.removeItem("carePlanPermission");
        localStorage.removeItem("carePlanUserInfo");
      }
    } catch (error) {
      dispatch(setAuthStatus({ isAuthenticated: false }));
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
      handleAPIError(error, props.history, dispatch);
      window.userManager.signoutRedirect();
    }
  };

  const handlehoverOption = (e: any, menu: any) => {
    if (menu.menus && menu.menus.length) {
      e.currentTarget.setAttribute("aria-expanded", "true");
    } else {
      e.currentTarget.removeAttribute("aria-expanded");
    }
  }
  const handleleaveOption = (e: any, menu: any) => {
    if (menu.menus && menu.menus.length) {
      e.currentTarget.setAttribute("aria-expanded", "false");
    } else {
      e.currentTarget.removeAttribute("aria-expanded");
    }
  }
  const history = useHistory();
  const handleRouteChange = () => {
    history.push("/profile")
  }
  // const handlebatchupdate = () => {
  //   history.push("/batchupdate");
  // }
  return (
    <AdminHeaderWrapper>
      <div className="aui-main-header">
        <a href="#maincontent" className="aui-skip-content">
          Skip to main content
        </a>
        <div className="aui-header-row">
          <div className="dashboard-nav-one navbar navbar-expand-lg justify-content-between mx-auto aui-header-content aui-header-t">
            <a
              href="#"
              className="float-left aha-heart-logo aha-contentone"
              aria-label="American Heart Association logo"
            ></a>
            <div className="d-flex">
              <button
                className="navbar-toggler ml-2 px-0"
                type="button"
                data-toggle="collapse"
                data-target="#toggleNavigator"
                aria-controls="toggleNavigator"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="aha-icon-hamburger"></i>
              </button>
              <div
                className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
                id="toggleNav"
              >
                <ul className="navbar-nav flex-lg-row flex-column mr-0">
                  <li className="d-flex nav-item dropdown flex-column ">
                    {user &&
                      <button
                        type="button"
                        className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                        id="navDropdown2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {`${user.firstName} ${user.lastName}`}
                      </button>
                    }
                    <div
                      className="dropdown-menu p-lg-0 aui-header-dropdown"
                      aria-labelledby="navDropdown2"
                    >
                      <NavLink
                        to="/profile"
                        className="dropdown-item py-2"
                      >
                        Profile
                      </NavLink>
                      {permission != undefined && feedBackFlag && <a
                        href="/feedback"
                        className="dropdown-item py-2"
                      >
                        Feedback
                      </a>}
                      <a href="/helpOrFAQ" className="dropdown-item py-2">Help/FAQ</a>
                      <NavLink
                        to="/batchupdate"
                        className="dropdown-item py-2"
                      >
                        Batch Processing
                      </NavLink>
                      <a
                        href="#"
                        onClick={signOut}
                        className="dropdown-item py-2"
                      >
                        Sign Out
                      </a>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-expand-lg  cp-bdr-resp">

          <nav
            className="mx-auto navbar collapse navbar-collapse flex-column flex-lg-row aui-header-content aui-header-b l-padding-0"
            id="toggleNavigator"
          >
            <ul className="navbar-nav cp-altone">
              <li className="nav-item dropdown mr-lg-5 cp-spl-lione">
              {user &&
                <button
                  type="button"
                  className="w-100 font-500 btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                  id="navDropdown1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                 {`${user.firstName} ${user.lastName}`}
                </button>
                }
                <div
                  className="dropdown-menu p-lg-0 aui-header-dropdown mb-0"
                  aria-labelledby="navDropdown1"
                >
                  <div
                    onClick={() => handleRouteChange()}
                    className="dropdown-item py-2"
                  >
                    Profile
                  </div>
                  {permission != undefined && feedBackFlag && <a
                        href="/feedback"
                        className="dropdown-item py-2"
                      >
                        Feedback
                      </a>}
                  <a href="/helpOrFAQ" className="dropdown-item py-2">Help/FAQ</a>
                  <NavLink
                        to="/batchupdate"
                        className="dropdown-item py-2"
                      >
                        Batch Processing
                      </NavLink>
                  <a href="#" onClick={signOut} className="dropdown-item py-2">
                    Sign Out
                  </a>
                </div>
              </li>

              {permission?.menus?.map((menu) => (
                <li key={menu.code} className={menu.menus ? " dropdown-submenu nav-item dropdown mr-lg-5" : "nav-item dropdown mr-lg-5"}>
                  {menu.display != false && <NavLink
                    activeClassName="li-active" aria-haspopup={menu.menus && menu.menus.length > 0 ? true : false} onMouseEnter={(e) => {
                      handlehoverOption(e, menu)
                    }} onMouseLeave={(e) => {
                      handleleaveOption(e, menu)
                    }}
                    className="nav-link"
                    to={{ pathname: menu.url, state: menu.actions }}
                  >
                    {menu.name}
                  </NavLink>
                  }

                  {menu.menus && (menu.code == 'reports' ? (reportFlag == true ?  true : false) : true) && (
                    
                    <ul className="dropdown-menu aui-header-dropdown">
                      
                      {menu.menus.map((submenu, index) => {
                        if (submenu.display != false) {
                          return (
                            <li><NavLink key={submenu.code + index}
                              className="dropdown-item py-2"
                              to={{
                                pathname: `${submenu.url}`,
                                state: submenu.actions,
                              }}
                            >{submenu.name}</NavLink></li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </AdminHeaderWrapper>
  );
};
export default AdminHeader;
