import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Routes from "routes";
import "styles/global.scss";
import ErrorBoundary from "common/ErrorBoundary";



function App() {
  return (
    <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
          <Routes />
      </DndProvider>
      </BrowserRouter>
    </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;
