import { CancelTokenSource } from "axios";
import { axiosContent } from "./axios";

export const getAllContent = async (): Promise<any> => {
  return axiosContent.get(`/contents`);
};
export const getAllContentBySize = async (size: number): Promise<any> => {
  return axiosContent.get(`/contents?pageSize=${size}`);
};
export const getContentByPage = async (
  size: number,
  pagenumber: number
): Promise<any> => {
  return axiosContent.get(
    `/contents?pageSize=${size}&pageNumber=${pagenumber}`
  );
};
export const getContentsCareplanPage = async (
  queryParams: any
): Promise<any> => {
  return axiosContent.get(
    `/contents?q=${queryParams.searchString}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&active=true`
  );
};
export const getContentBySearch = async (
  size: number,
  searchvalue: string,
  assetLinked: boolean = false,
  source: any
): Promise<any> => {
  let queryParams = `pageSize=${size}&q=${searchvalue}`;
  if (assetLinked) queryParams += `&assetLinked=false`;
  return axiosContent.get(`/contents?${queryParams}`, {
    cancelToken: source.token,
  });
};
export const getContentById = async (id: number): Promise<any> => {
  return axiosContent.get(`/contents/${id}`);
};

export const getAllCareplans = async (): Promise<any> => {
  return axiosContent.get(`/careplans`);
};
export const getAllCareplanBySize = async (size: number): Promise<any> => {
  return axiosContent.get(`/careplans?pageSize=${size}`);
};
export const getCareplanByPage = async (pagenumber: number): Promise<any> => {
  return axiosContent.get(`/careplans?pageNumber=${pagenumber}`);
};
export const getCareplanBySearch = async (
  searchvalue: string
): Promise<any> => {
  return axiosContent.get(`/careplans?q=${searchvalue}`);
};
export const getCategoryList = async (
  name: string,
  search: string,
  source: any
): Promise<any> => {
  return axiosContent.get(`/categories?name=${name}&q=${search}&listAll=true`, {
    cancelToken: source.token,
  });
};
export const getAssessmentSummary = async (
  id:any,
): Promise<any> => {
  return axiosContent.get(`/assessments/${id}/contents?active=true`);
};
export const editAssessmentNotify = async (
  id: number,
  enabled: boolean,
  notify: boolean,
): Promise<any> => {
  return axiosContent.patch(
    `/assessments/${id}/active?enabled=${enabled}&notify=${notify}`
  );
};
export const getContentTypes = async (source: any): Promise<any> => {
  return axiosContent.get(`/contentTypes?pageOrder=name asc`, { cancelToken: source.token });
};

export const getTopContentsCount = async ():Promise<any>=>{
  return axiosContent.get(`/topContentsCount`);
}

export const editContent = async (
  id: number,
  updateExisting: boolean,
  notify: boolean,
  data: any,
  source: CancelTokenSource
): Promise<any> => {
  return axiosContent.patch(
    `/contents/${id}?updateExisting=${updateExisting}&notify=${notify}`,
    data,
    { cancelToken: source.token }
  );
};
export const AddContent = async (
  data: any,
  source: CancelTokenSource
): Promise<any> => {
  return axiosContent.post(`/contents`, data, { cancelToken: source.token });
};
export const getCarePlanByFilter = async (content: any): Promise<any> => {
  return axiosContent.get(
    `/careplans?pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&pageNumber=${content.pageNumber}`
  );
};
export const getContentByFilter = async (
  content: any,
  source: any
): Promise<any> => {
  const advanceFilter = content.filter;
  const categories = Object.keys(advanceFilter);
  let queryParams = "";
  for (let keys of categories) {
    if (content.filter[keys].length > 0) {
      if (keys === "contentType") {
        queryParams += `&contentTypeCode=${content.filter[keys].join(",")}`;
      } else {
        queryParams += `&category.${keys}=${content.filter[keys].join(",")}`;
      }
    }
  }
  return axiosContent.get(
    `/contents?pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&sequenceOrder=${content.sequenceOrder}&sortOrder=${content.sortOrder}&pageNumber=${content.pageNumber}${queryParams}`,
    { cancelToken: source.token }
  );
};
export const getContentByCategory = async (
  content: any,
  source: any
): Promise<any> => {
  return axiosContent.get(
    `/contents?q=${content.search}&pageSize=${
      content.pagesize
    }&category.category=${content.filter.category.join(",")}`,
    { cancelToken: source.token }
  );
};

export const getContentSync = async (
  content: any,
  source: any
): Promise<any> => {
  return axiosContent.get(
    `/syncLogs?q=${content.search}&pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&pageNumber=${content.pageNumber}`,
    { cancelToken: source.token }
  );
};

export const getCareplanById = async (id: number) => {
  return axiosContent.get(`/careplans/${id}`);
};

export const getContentSyncById = async (id: number) => {
  return axiosContent.get(
    `/syncLogs/${id}`
  );
};
export const getAssessmentById = async (id: number) => {
  return axiosContent.get(`/assessments/${id}`);
};
export const addCarePlan = async (careplan: any): Promise<any> => {
  return axiosContent.post(`/careplans`, careplan);
};
export const updateCarePlan = async (id:number,updateExisting:boolean,notify:boolean,careplan: any): Promise<any> => {
  return axiosContent.patch(`/careplans/${id}?updateExisting=${updateExisting}&notify=${notify}`, careplan);
};
export const AddCareplanVendor = async (vendorID:number, code:any): Promise<any> =>{
  return axiosContent.post(`/careplans/vendors`,{vendorID,code})
}
export const DeleteCareplanVendor =async (id : Number):Promise<any> => {
  return axiosContent.delete(`careplanVendors/${id}`)
}
export const getAssessments = async (assessments:any) => {
  const type = assessments.filter.questionType;
  return axiosContent.get(`/assessments?pageSize=${assessments.pageSize}&pageOrder=${assessments.pageOrder}&q=${assessments.search}&pageNumber=${assessments.pageNumber}&type=${type.join(",")}`);

};
export const getDropDownAssessments = async (assessments:any) => {
  return axiosContent.get(`/assessments?pageSize=${assessments.pageSize}&name=${assessments.search}&active=true`);

};
export const getQuestionTypes = async (): Promise<any> => {
  return axiosContent.get(`/questionTypes`);
};
export const postCategory = async (category: any): Promise<any> => {
  return axiosContent.post(`/categories`, category);
};
export const getCategoryLevelZero = async (): Promise<any>=>{
  return axiosContent.get("/categories?level=0");
}
export const addAssessment = async (assessment: any): Promise<any> => {
  return axiosContent.post(`/assessments`, assessment);
};
export const patchAssessment = async (id:any,existance:boolean,notify:boolean,assessment: any): Promise<any> => {
  return axiosContent.patch(`/assessments/${id}?updateExisting=${existance}&notify=${notify}`, assessment);
};
export const patchContentWithEmailNotification = async (
  id: number,
  enabled: boolean,
  notify: boolean,
  source: CancelTokenSource
): Promise<any> => {
  return axiosContent.patch(
    `/contents/${id}/active?enabled=${enabled}&notify=${notify}`,
    { cancelToken: source.token }
  );
};
export const getContentByAssetId = async (
  id: number
): Promise<any> => {
  return axiosContent.get(
    `/assets?id=${id}&enabled=true`
  );
};

export const downloadContentData = async (i?:any):Promise<any> => {
  return axiosContent.get(`/contents?pageSize=${process.env.REACT_APP_FILE_DOWNLOAD_PAGESIZE}&pageNumber=${i}`)
}

export const BatchUploadFile = async (file:any):Promise<any> => {
  return axiosContent.post('/uploadFile', file)
}

export const BatchUploadFileById = async (id: any, payload: any, source: CancelTokenSource): Promise<any> => {
  return axiosContent.patch(`/batchUpdate/${id}`, payload, { cancelToken: source.token })
}

export const GetBatches = async (query: any, source: any): Promise<any> => {
  return axiosContent.get(`/listBatch?pageSize=${query.pageSize}&pageOrder=createdAt desc&pageNumber=${query.pageNumber}`, { cancelToken: source.token })
}

export const GetBatchDetailsById = async (id: any, query: any, source: any, status: boolean): Promise<any> => {
  return axiosContent.get(`/listBatchDetails/${id}?status=${status}&pageSize=${query.pageSize}&pageNumber=${query.pageNumber}`, { cancelToken: source.token })
}

export const BrokenLinkCheck = async ():Promise<any> => {
  return axiosContent.post('/brokenLinkCheck?contentTypeCode=Link,Web,Video,Article,Animation,Infographic')
}

export const GetBrokenLinkBatches = async (query: any, source: any): Promise<any> => {
  return axiosContent.get(`/listBatchBrokenlink?pageSize=${query.pageSize}&pageOrder=createdAt desc&pageNumber=${query.pageNumber}`, { cancelToken: source.token })
}

export const GetBrokenLinkDetailsById = async (id: any, query: any, source: any, status: boolean): Promise<any> => {
  return axiosContent.get(`/listBrokenLinkDetails/${id}?status=${status}&pageSize=${query.pageSize}&pageNumber=${query.pageNumber}`, { cancelToken: source.token })
}

export const DownloadBrokenLinkDetailsById = async (id: any, query: any, source: any, status: boolean, pageNumber: any): Promise<any> => {
  return axiosContent.get(`/listBrokenLinkDetails/${id}?status=${status}&pageSize=${process.env.REACT_APP_FILE_DOWNLOAD_PAGESIZE}&pageNumber=${pageNumber}`, { cancelToken: source.token })
}