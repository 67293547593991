import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Auth {
  isAuthenticated: boolean;
  permission:Permission;
  roleActions:any;
}
export interface Permission {
  menus?: (MenusEntity)[] | null;
}
export interface MenusEntity {
  display: any;
  url: string;
  code: string;
  name: string;
  actions: Actions;
  landingPage?: boolean | null;
  menus?: (MenusEntity)[] | null;
}
export interface Actions {
  add?: boolean;
  edit?: boolean;
  view?: boolean;
  delete?: boolean;
}


export const initialState: Auth = {
    isAuthenticated: false,
    permission:{},
    roleActions:{},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<{isAuthenticated:boolean}>) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setPermission:(state,action:PayloadAction<Permission>)=>{
     state.permission = action.payload;
    },
    setRoleAction:(state,action:PayloadAction<any>)=>{
      state.roleActions = action.payload;
     }
    
  },
});
const { actions, reducer } = authSlice;

export const { setAuthStatus,setPermission,setRoleAction } = actions;
export default reducer;
