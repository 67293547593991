import React, { forwardRef } from 'react'
import { DialogBoxWrapper } from "./styled";

export interface DialogBoxProperty{
    message:string;
    confirmHandler: Function;
  }
const DialogBox = (props : any,ref:any) => {
    return (<DialogBoxWrapper>
             <div className="aui-sm-noticebanner"> 
                 <div className="modal fade pl-3 pr-3" id="aui-sm-noticebanner_update" tabIndex={-1} aria-label="Modal" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered modal-lg aha-card-modal cpr-confirm-alert">
                        <div className="modal-content border-0">
                            <div className="modal-body p-0">
                                <p className="text-center">
                                {props.message}
                                </p>
                                {props.children}
                                <div className="cpr-buttons text-center">
                                <button type="button" className="btn btn-round btn-secondary pl-4 pr-4" data-dismiss="modal" data-testid="dialogBoxWrapperCancel" aria-label="Cancel" onClick={props.cancelHandler}>Cancel</button>
                                <button type="button" className="btn btn-round btn-primary pl-4 pr-4" aria-label="confirm" onClick={props.confirmHandler}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
              </div>
             <button style={{visibility:"hidden"}} ref={ref} data-toggle="modal" data-target="#aui-sm-noticebanner_update"></button>
             </DialogBoxWrapper>
    )
}

export default forwardRef(DialogBox)
