import CryptoJS from "crypto-js";                     
export const encryptData = (data:any, salt:any) =>{
  const encripted = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
  return encripted;
}


export const decryptData = (ciphertext:any, salt:any) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
  try {
     const decripted =  JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
     return decripted
  }
  catch(err){
    return null;
  }
}
