/* eslint-disable no-debugger, no-console */
import { logger } from "common/utils/logger.utils";
import React from "react";
import HeaderWrapper from "./styled";


const Header = () => {

  const signout = () => {
    logger("clicked signout");
    window.userManager.signoutRedirect();
    localStorage.removeItem("carePlanAccessToken");
  };

  return (
    <HeaderWrapper className="aui-main-header aui-pri-header">
      <a href="#maincontent" className="aui-skip-content">
        Skip to main content
      </a>
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
        <a
          href="/"
          className="aha-certification-logo"
          aria-label="Quality and Certification tool logo"
        >
          logo
        </a>
        <button
          className="navbar-toggler ml-2 px-0"
          type="button"
          data-toggle="collapse"
          data-target="#toggleNav"
          aria-controls="toggleNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="aha-icon-hamburger" />
        </button>
        <div
          className="justify-content-lg-end collapse navbar-collapse aui-pri-nav"
          id="toggleNav"
        >
          <ul className="navbar-nav mx-lg-3 flex-lg-row flex-column">
            <li className="d-flex nav-item dropdown px-lg-3 flex-column">
              <button
                type="button"
                className="btn btn-text dropdown-toggle flex-grow-1 text-left nav-link"
                id="navDropdown1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                My Account
              </button>
              <div
                className="dropdown-menu p-lg-0 aui-header-dropdown"
                aria-labelledby="navDropdown1"
              >
                <div
                  onClick={signout}
                  className="dropdown-item py-2"
                  tabIndex={0}
                  role="button"
                >
                  Sign Out
                </div>
              </div>
            </li>
            <li className="d-flex nav-item px-lg-3">
              <a
                href="https://www.heart.org/en/volunteer/opportunities"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                Volunteer
              </a>
            </li>
          </ul>
          <a
            href="https://www2.heart.org/site/SPageNavigator/donatenow_heart.html?s_src=20U2W1UEMG&s_subsrc=footer_donatenow"
            target="_blank"
            rel="noreferrer"
            className="btn btn-round btn-primary donate-btn"
            role="button"
          >
            Donate
          </a>
        </div>
      </nav>
    </HeaderWrapper>
  );
};
export default Header;
