import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface brokenLinksFailedState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  isActive: any;
  filter:any;
}

export const initialState: brokenLinksFailedState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  isActive: "",
  filter: {
    vendorType: [],
    vendorsId: [],
    vendorsCode: [],
    pageSize: 10,
    search: ""
  }
}

const brokenLinksFailedSlice = createSlice({
  name: "brokenLinkViewFailed",
  initialState,
  reducers: {
    setPageNumberFailed: (state:any, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    searchAsset: (state:any, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload
    },
    setPageSizeFailed: (state:any, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLoader: (state:any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  }
})

const { actions, reducer } = brokenLinksFailedSlice;

export const { setPageNumberFailed, searchAsset, setPageSizeFailed, setLoader } = actions
export default reducer