import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const changeDateFormat = (date: any) => {
  const [dateF] = new Date(date).toISOString().split('T')
  return dateF
}


export interface VendorActivityState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  fromDate: string;
  toDate: string;
  isActive: any;
  filter:any;
}

export const initialState: VendorActivityState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  fromDate: changeDateFormat(new Date().setDate(new Date().getDate() - 30)),
  toDate: changeDateFormat(new Date()),
  isActive: "",
  filter: {
    vendorType: [],
    vendorsId: [],
    vendorsCode: [],
    pageSize: 10,
    search: ""
  }
}

const vendorActivitySlice = createSlice({
  name: "vendorActivity",
  initialState,
  reducers: {
    setPageNumber: (state:any, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    searchAsset: (state:any, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload
    },
    setPageSize: (state:any, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLoader: (state:any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setFromDate: (state:any, action:PayloadAction<any>) => {
      state.fromDate = action.payload;
    },
    setCategoryFilter: (state:any, action: any) => {
      state.pageNumber = 1;
      state.filter[action.payload.name] = [...action.payload.value];

    },
    setToDate: (state:any, action:PayloadAction<any>) => {
      state.toDate = action.payload;
    },
    searchVendors: (state:any, action: PayloadAction<string>) => {
      state.filter.search = action.payload
    },
  }
})

const { actions, reducer } = vendorActivitySlice;

export const { setCategoryFilter, searchVendors, setPageNumber, searchAsset, setPageSize, setLoader, setFromDate, setToDate } = actions
export default reducer