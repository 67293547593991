/* eslint-disable no-debugger, no-console */
import { Permission, setAuthStatus, setPermission, setRoleAction } from "app/authReducer";
import { encryptData } from "common/utils/cryptoJs";
import { logger } from "common/utils/logger.utils";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminSignIn, ssoSignUp } from "services/api/adminLogin.api";
import { SSOLoginWrapper } from "./styled";
import { userLogin } from "./user.slice";

interface Credentials {
  email: any;
  password: string;
  idToken: string;
}
export interface Token {
  idToken: string;
}

const Landing = (props: any) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  const userState = useSelector((state: any) => {
    return state.user;
  });
  const modifiedMenus: any = {};
  const modifyMenus = (menus: any) => {
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].hasOwnProperty("menus")) {
        modifiedMenus[menus[i].code] = { actions: menus[i].actions }
        modifyMenus(menus[i].menus);
      } else {
        modifiedMenus[menus[i].code] = { actions: menus[i].actions }
      }
    }
  }

  const signIn = async (credentials: Credentials) => {
    let landingMenu: any = null;
    adminSignIn(credentials).then((response: any) => {
      const result = response.data;
      const data = result.data;
      const user = data.user;
      const token = user.token;
      const permission: Permission = user.permission;
      if (
        typeof permission === "object" &&
        Array.isArray(permission.menus) &&
        permission.menus.length > 0
      ) {
        landingMenu = permission.menus.filter((menu) => menu.landingPage);
        modifyMenus(permission.menus);
        localStorage.setItem("carePlanPermission",  encryptData(permission,process.env.REACT_APP_CLIENT_SALT));
        
        const encriptedData = encryptData(modifiedMenus,process.env.REACT_APP_CLIENT_SALT);
        localStorage.setItem("actions", encriptedData);
        dispatch(setPermission(permission));
        dispatch(setRoleAction(modifiedMenus));
      }
      if (token?.accessToken !== "" || token?.refreshToken !== "") {
        localStorage.setItem("carePlanAccessToken", token.accessToken);
        localStorage.setItem("carePlanRefreshToken", token.refreshToken);
      
        permission
          ? landingMenu?.length
            ? props.history.replace({
              pathname: landingMenu[0]?.url,
              state: landingMenu[0].actions,
            })
            : props.history.replace({
              pathname: permission.menus
                ? permission.menus[0]?.url
                : "/dashboard",
              state: permission.menus
                ? permission.menus[0].actions
                : { view: true },
            })
          : props.history.replace("/inactive");
          window.location.reload();
      }
     
      dispatch(userLogin(token));
      dispatch(setAuthStatus({ isAuthenticated: true }));
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }).catch((error) => {
      const token = { idToken: credentials.idToken }
      ssoSignUp(token).then((response: any) => {
        const token = response.data?.data?.user?.token;
        if (token?.accessToken !== "" || token?.refreshToken !== "") {
          localStorage.setItem("carePlanAccessToken", token.accessToken);
          localStorage.setItem("carePlanRefreshToken", token.refreshToken);
        }
        dispatch(userLogin(token));
        props.history.replace("/inactive");
        dispatch(setAuthStatus({ isAuthenticated: true }));
      }).catch((error) => {
        props.history.replace("/");
        dispatch(setAuthStatus({ isAuthenticated: false }));
      });
    })
  };



  useEffect(() => {
    logger("TOKEN:", userState.ssoAccessToken, "COUNT:", count);
    if (userState.ssoAccessToken && count === 1) {
      setCount(2);
      logger("user loaded:", userState);
      signIn({ "email": userState.ssoUser.email, "password": "", idToken: userState.ssoAccessToken });
    }
  }, [userState.ssoAccessToken, count]);

  useEffect(() => {
    setCount(1);
    logger("Landing Loaded");
  }, []);

  return (
    <SSOLoginWrapper>
      <div className="container">
          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center progress-welcome">
            <h1 className="h2 mb-0 mr-sm-3 mt-3 font-600  progress-welcome-title">
              Welcome to <span className="font-700">CarePlan</span>
            </h1>
            <img
              src="/images/progress_logo_animation.gif"
              alt="logo"
              className="progress-img"
            />
          </div>
        </div>

    </SSOLoginWrapper>
  );
};

export default Landing;
