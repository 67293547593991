import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface FilterInterface {
  category:string[];
  careplan:string[];
  level:string[];
  coach:string[];
  owner:string[];
  domain:string[];
  assessment:string[];
  asset:string[];
}
export interface CategoryInterface{
  id: number;
  name: string;
  code:string;
  type?: string;
  level?: number;
  parentid?: number;
  createdAt: string;
  updatedAt:string;
  isChecked?:boolean;
}
export interface AssessmentInterface{
  id: number;
  name: string;
  code:string;
  type?: string;
  createdAt: string;
  updatedAt:string; 
  description?:string;
  isActive:number;
  isLinked:number;

}
export interface AssetInterface{
  id: number;
  name: string;
  code:string;
  filesize: number;
  fileName: string;
  assetTypeName: string;
  mimeTypeName: string;
  createdAt: string;
  updatedAt:string; 
  isActive:number;
  isLinked:number;

}
export interface Category{
  category:CategoryInterface[];
  careplan:CategoryInterface[];
  level:CategoryInterface[];
  coach:CategoryInterface[];
  owner:CategoryInterface[];
  domain:CategoryInterface[];
  assessment:AssessmentInterface[];
  asset:AssetInterface[]

}
export interface CategoryPayload{
  name:string;
  value : CategoryInterface[]
}
interface AssessmentFilter{
  search:string;
  pageSize:number;
  loader:boolean;
}
interface AssetFilter{
  search:string;
  pageSize:number;
  loader:boolean;
}
export interface ContentManagementState {
  assessment:AssessmentFilter;
  asset: AssetFilter;
  filter: FilterInterface;
  data : Category;
}

export const initialState: ContentManagementState = {
  assessment:{
    search: "",
    pageSize: 10,
    loader:false
  },
  asset:{
    search: "",
    pageSize: 10,
    loader:false
  },
  filter:{
   category:[],
   careplan:[],
   level:[],
   coach:[],
   owner:[],
   domain:[],
   assessment: [],
   asset:[]
  },
  data:{
    category:[],
    careplan:[],
    level:[],
    coach:[],
    owner:[],
    domain:[],
    assessment: [],
    asset:[]
  }
};
interface CategoryFilter{
  name:string;
  value : string[];
}

const editContentSlice = createSlice({
  name: "editcontent",
  initialState,
  reducers: {
    clearState: (state) => {
       return initialState;
    },
    setLoader:(state,action)=>{
      state.assessment.loader = action.payload; 
    },
    setCategoryFilter : (state,action:PayloadAction<CategoryFilter>)=>{
      switch(action.payload.name){
        case "category":state.filter.category = [...action.payload.value];break;
        case "careplan":state.filter.careplan = [...action.payload.value];break;
        case "level":state.filter.level = [...action.payload.value];break;
        case "coach":state.filter.coach = [...action.payload.value];break;
        case "owner":state.filter.owner = [...action.payload.value];break;
        case "domain":state.filter.domain = [...action.payload.value];break;
        case "assessment":state.filter.assessment = [...action.payload.value];break;
        case "asset":state.filter.asset = [...action.payload.value];break;
      }
      
    },
    setAssessmentSearch :(state,action)=>{
      state.assessment.search= action.payload;
    },
    setAssetSearch :(state,action)=>{
      state.asset.search= action.payload;
    },
    setEditCategoryData : (state,action:PayloadAction<any>)=>{
      switch(action.payload.name){
        case "category":state.data.category = [...action.payload.value];break;
        case "careplan":state.data.careplan = [...action.payload.value];break;
        case "level":state.data.level = action.payload.value;break;
        case "coach":state.data.coach = [...action.payload.value];break;
        case "owner":state.data.owner = [...action.payload.value];break;
        case "domain":state.data.domain = [...action.payload.value];break;
        case "assessment":state.data.assessment = [...action.payload.value];break;
        case "asset":state.data.asset = [...action.payload.value];break;
       }
    }

  }
});
const { actions, reducer } = editContentSlice;

export const { clearState,setCategoryFilter, setLoader,setAssessmentSearch,setAssetSearch,setEditCategoryData} = actions;
export default reducer;
