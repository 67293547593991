import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface batchViewSuccessState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  isActive: any;
  filter:any;
}

export const initialState: batchViewSuccessState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  isActive: "",
  filter: {
    vendorType: [],
    vendorsId: [],
    vendorsCode: [],
    pageSize: 10,
    search: ""
  }
}

const batchViewSuccessSlice = createSlice({
  name: "batchUpdateViewSuccess",
  initialState,
  reducers: {
    setPageNumberSuccess: (state:any, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    searchAsset: (state:any, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload
    },
    setPageSizeSuccess: (state:any, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLoader: (state:any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  }
})

const { actions, reducer } = batchViewSuccessSlice;

export const { setPageNumberSuccess, searchAsset, setPageSizeSuccess, setLoader } = actions
export default reducer