import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";

export interface RoleManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: RoleManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: ""
};

const roleManagementSlice = createSlice({
  name: "roleManagement",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    search: (state, action: PayloadAction<string>) => {
      state.pageNumber=1;
      state.search = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },

  },
});
const { actions, reducer } = roleManagementSlice;

export const { setPageNumber,setLoader, setPageOrder, search, setPageSize} = actions;
export default reducer;
