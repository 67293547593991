import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FeedbackManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  pageOrder:string;
  loader:boolean;
  isDataUpdated: boolean;
}

export const initialState: FeedbackManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    pageOrder:"id desc",
    loader:false, 
    isDataUpdated: false
};

const assetManagementSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
  setPageNumber : (state:any,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
  searchAsset : (state:any,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload
  },
  setPageSize: (state:any, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
    setPageOrder: (state: any, action: any)=>{
        state.pageOrder = action.payload;
  },
  clearVendorState : ()=>{
     return initialState
   },
  setCommentLoader: (state:any, action: PayloadAction<boolean>) => {
    state.loader = action.payload;
  },
  setisDataUpdated: (state:any, action:any) => {
    state.isDataUpdated = action.payload
  }
  },  
});
const { actions, reducer } = assetManagementSlice;

export const { setPageNumber, setisDataUpdated, setCommentLoader,searchAsset,setPageSize,clearVendorState,setPageOrder} = actions;
export default reducer;
