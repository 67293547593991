import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Nav } from 'rsuite'
import ProfileContent from './ProfileContent'
import { AccountInformationWrapper } from './styled'
const Accounts = (props:any) => {
  const [showprofilePage, setshowProfilePage] = useState(true)
  const userInfo = useSelector((state => {
    return state
  }))
  const handle_accountdetails = (event:any)=>{
    event.currentTarget.nextSibling.classList.toggle("d-block");
  }
  return (
    
    <AccountInformationWrapper>
      <div className="cp-profile-main">
        <div className="cp-profile-container mx-auto">

          <div className='child-container d-flex mb-5 mt-4 pt-2'>
            <div className="myAccount-left" onClick={handle_accountdetails}>             
              <div data-testid="myProfile" className="myAccount-heads pt-3 pl-4 pb-3 pr-3 mb-2">
                 My Profile
                 <i className="acc-btn-arrow aha-icon-arrow-down float-right d-none"></i>
                </div>              
            </div>
            <div className="myAccount-right ml-3 pl-1">
              {showprofilePage && <ProfileContent />}
            </div>
          </div>
        </div>
      </div>
    </AccountInformationWrapper>
  )
}

export default Accounts