import styled from "styled-components";
export const ContactUsWrapper = styled.div`
body{overflow-x:hidden;}
.aha-breadcrumb a{font-size:16px;}
.cp-addbtn:focus,.iti__selected-flag:focus{outline: 2px solid #F7983A !important;
  -webkit-box-shadow: 0 0 4px 4px #F7983A !important;
          box-shadow: 0 0 4px 4px #F7983A !important; }
.aha-breadcrumb .breadcrumb-item{color:#C10E21;}
.iti__dial-code{color:#222328;}
.aha-breadcrumb .breadcrumb-item:last-child{color:#707070;}
.sub-heads{font-size:16px;}
.aha-breadcrumb .breadcrumb-item:last-child:after{content:" ";}
.sub-heads-pr{border-bottom:2px solid #C10E21;
  h1.cp-h2 {font-size: 30px;margin: 20px 0px 20px 0px;}}
.cp-alink{margin-top: 20px;
 .cp-addbtn{font-size: 26px;cursor:pointer;}}
 .li-one-ul li{margin: 5px 10px 5px 15px !important;}
.bdr-btm {border-bottom: 1px solid #BCC3CA;margin-top: 70px;}
.invalid-email{width: 100%;display: block;color: #c10e21;font-size: 0.875rem;line-height: 1;
  margin-top: 10px;float: right;text-align: right;}
.btn-div{margin:40px 0px 185px 0px;
  button{width:141px;}}
  input.form-control,select.form-control{height:40px !important;}
  .iti,.iti__flag-container,#iti-0__country-listbox{ width: 100%; }
  .iti__selected-flag{width:78px;}
  @media screen and (min-width: 1150px) {
  .feild-width{max-width:350px;width:350px;}  
  }  
  @media screen and (max-width: 767.5px) {
    .sub-heads-pr h1.cp-h2 {font-size: 24px;margin: 40px 0px 20px 0px;}
    .no-row-cls{margin-left:0px !important;margin-right:0px !important;} 
   form.row{margin-right: 0px !important;margin-left: 0px;width: 100%;
   .form-group{padding:0px !important;}
   .lead-content-wrap{display:block !important;}
   .businesslead-content{margin-left:0px !important;}
  .techlead-content,.businesslead-content{width:100% !important;}}  
  .cp-alink {margin-top: 40px;}  
  }
  @media screen and (max-width: 575.5px) {
    .sub-heads-pr{margin-bottom:30px !important;}
    .sub-heads{margin-bottom:0px !important;padding-left:0px;}
    .no-row-cls label{padding-left:0px;}
    .bdr-btm{margin-top:40px;margin-left:0px;}
    .btn-div{margin: 44px 0px 67px auto !important;
      button{width:140px;margin-right: 0px !important;
        margin-left: 10px !important;}
        button:nth-of-type(1){margin-left:0px !important;}}
  }    
`;

export const AnotherWraper = styled.div``;
