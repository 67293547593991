import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssessmentManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  loader:boolean;
  pageOrder:string;
  filter:AssetManagementFilter
}
export interface AssetManagementFilter{
  questionType:string[];
}
export const initialState: AssessmentManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    loader:false,
    pageOrder:"",
    filter:{
      questionType:[]
    }
   
};
interface AssessmentFilter{
  name:string;
  value : string[];
}
const assetManagementSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
    search : (state,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
  setCategoryFilter : (state,action:PayloadAction<AssessmentFilter>)=>{
    state.pageNumber = 1;
    state.filter.questionType = [...action.payload.value];
    
  },
  setPageOrder:(state,action)=>{
    state.pageOrder = action.payload;
},
  setLoader  : (state,action:PayloadAction<boolean>)=>{
      state.loader = action.payload;
  },
  clearState : ()=>{
     return initialState
   }
  },  
});
const { actions, reducer } = assetManagementSlice;

export const { setPageNumber,setLoader,setPageOrder ,search,setPageSize,clearState,setCategoryFilter} = actions;
export default reducer;
