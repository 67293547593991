import { CancelTokenSource } from "axios";
import { axiosAggregate, axiosAccounts } from "./axios";
export const getdashboardContent = async (source:CancelTokenSource): Promise<any> => {
    return axiosAggregate.get(`/dashboardSummary`,{cancelToken: source.token});
  };

export const callSync = async ():Promise<any>=>{
  return axiosAggregate.post(`/sync`);
}
export const getAssessmentSummary = async (id:any):Promise<any>=>{
  return axiosAggregate.get(`/assessments/${id}/summary`);
}

export const getActivitiesCount = async (fromDate: any, ToDate: any, count:number):Promise<any>=>{
  return axiosAggregate.get(`/activitiesCount?fromDate=${fromDate}&toDate=${ToDate}&count=${count}`);
}

export const getVendorActiveCount = async (fromDate: any, ToDate: any):Promise<any>=>{
  return axiosAccounts.get(`/vendorCount?fromDate=${fromDate}&toDate=${ToDate}`);
}

export const getUserActiveCount = async (fromDate: any, ToDate: any):Promise<any>=>{
  return axiosAccounts.get(`/userCount?fromDate=${fromDate}&toDate=${ToDate}`);
}

export const getParticipantsCount = async (fromDate: any, ToDate: any):Promise<any>=>{
  return axiosAggregate.get(`/participantCount?fromDate=${fromDate}&toDate=${ToDate}`);
}
