import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface brokenLinkState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  isActive: any;
  filter:any;
  refresh: boolean;
}

export const initialState: brokenLinkState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  isActive: "",
  filter: {
    vendorType: [],
    vendorsId: [],
    vendorsCode: [],
    pageSize: 10,
    search: ""
  },
  refresh: false
}

const brokenLinkSlice = createSlice({
  name: "brokenLink",
  initialState,
  reducers: {
    setPageNumber: (state:any, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    searchAsset: (state:any, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload
    },
    setPageSize: (state:any, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLoader: (state:any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setRefresh: (state:any, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },
  }
})

const { actions, reducer } = brokenLinkSlice;

export const { setPageNumber, searchAsset, setPageSize, setLoader, setRefresh } = actions
export default reducer