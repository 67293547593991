import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Loader from "components/Loader";
import Toast from "components/Toast";
import AdminFooter from "components/AdminFooter";
import AdminHeaderWrapper from "components/AdminHeader/styled";
interface Props {
  children: ReactNode;
}
export default function LoginLayout({ children }: Props) {
  return (
    <>
      <Toast />
      <LoginHeader />
      <Loader />
      <main id="main-container">{children}</main>
      <AdminFooter />
    </>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const LoginHeader = () => {
  return (
    <AdminHeaderWrapper>
      <div className="aui-main-header aui-pri-header">
        <a href="#main-container" className="aui-skip-content">
          {" "}
          Skip to main content
        </a>
        <nav className="navbar navbar-expand-lg justify-content-between mx-auto aui-header-content aui-header-t">
          <a
            href="/"
            // className="aha-heart-logo aha-contentone"
            aria-label="American Heart Association logo"
          >
            <img src="/images/AHA-100.svg" />
          </a>
          <button className="navbar-toggler ml-2 px-0" type="button" data-toggle="collapse" data-target="#toggleNav" aria-controls="toggleNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="aha-icon-hamburger"></i>
          </button>
          <div className="justify-content-lg-end collapse navbar-collapse aui-pri-nav" id="toggleNav">
            <ul className="navbar-nav mx-lg-3 flex-lg-row flex-column">
              <li className="d-flex nav-item px-lg-3">
                <button type="button" className="btn btn-text nav-link" onClick={(e) => {
                  e.preventDefault();
                  const url = 'https://www.heart.org/en/volunteer/opportunities/';
                  window.open(url, '_blank');
                }}>Volunteer</button>
              </li>
            </ul>
            <a href="https://www.heart.org/" target="_blank" className="btn btn-round btn-primary donate-btn" role="button">Donate</a>
          </div>
        </nav>
      </div>
    </AdminHeaderWrapper>
  );
};
